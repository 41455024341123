import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import { MediaQueries } from 'inno-components'
import cn from 'classnames'
import AltertineLayout from './layouts/AltertineLayout'
import Img from 'react-image'
import Button from '@material-ui/core/Button'
import Helmet from 'react-helmet'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  },
  portfolioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '60vh',
    [MediaQueries.small]: {
      height: '120vh'
    }
  },
  /*
  container: {
    columnCount: "2",
    columnGap: "1em",
    [MediaQueries.small]: {
      columnCount: "3",
      columnGap: "2em"
    }
  },*/
  portfolioItem: {
    width: '50%',
    padding: '1em',
    //overflow: "hidden",
    [MediaQueries.small]: {
      width: '50%',
      padding: '2em'
    }
  },
  link: {
    width: '100%'
  },
  picture: {
    width: '100%'
  }
})

const items = [
  {
    picture:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/gallerie/photo1.jpg'
  },
  {
    picture:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/gallerie/photo2.jpg'
  },
  {
    picture:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/gallerie/photo3.jpg'
  },
  {
    picture:
      'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/gallerie/photo4.jpg'
  }
]

const IndexPage = () => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)

  const Items = items.map(item => (
    <div
      className={cn('photography', css(styles.portfolioItem))}
      key={item.postId}
    >
      <a
        href={item.permalink}
        target="_blank"
        rel="noopener noreferrer"
        className={cn('portfolio_item', 'work-grid', css(styles.link))}
      >
        <Img src={item.picture} alt="image" className={css(styles.picture)} />
        <div className="portfolio_item_hover">
          <div className="item_info" style={{ width: '100%' }}>
            <span />
          </div>
        </div>
      </a>
    </div>
  ))

  return (
    <AltertineLayout image="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/churchill/ecole.jpg">
      <Helmet
        title="École de tennis - Tennis Club Uccle Churchill"
        meta={[
          {
            name: 'description',
            content:
              "Vous recherchez des cours de tennis pour votre enfant? Découvrez ici toutes les informations pratiques liées à l'école de tennis du Tennis Club Uccle Churchill"
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">école de tennis</h2>
              </div>
            </div>
          </div>
          <div className="row center" style={{ margin: '2em 0' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                L’école de tennis du Churchill fait désormais partie d’un
                groupement de 3 écoles de tennis, toutes situées à proximité du
                parc Montjoie à Uccle
              </p>
              <p>
                Pour toute information complémentaire ou demande d'inscription
                en ligne, rendez-vous sur le site de
              </p>
            </div>
          </div>
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  <a
                    href="https://www.brusselstennisschool.be"
                    title="Brussels Tennis School"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline'
                    }}
                  >
                    La Brussels Tennis School
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row center">
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
                De nombreux cours et stages se déroulent sur le site du Tennis
                Club Uccle Churchill.
              </p>
              <p>
                La famille Ulens offre un accueil très attentionné à tous les
                élèves du site, et particulièrement les plus petits.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="portfolio">
        <div className="container">
          <div className="col-md-12">
            <div className={cn(css(styles.portfolioContainer))}>{Items}</div>
          </div>
        </div>
      </section>
    </AltertineLayout>
  )
}

export default IndexPage
